<template>
  <div class="checkout-order-details">
    <section>
      <div class="container is-fullhd">
        <h3 class="title is-3 has-text-centered pb-6">Tramitar pedido</h3>
        <div class="checkout-wrap">
          <div class="tab-wrap">
            <div class="columns is-justify-content-center is-align-items-center">
              <div class="column has-text-weight-bold txt-yellow is-size-5 has-text-centered">Tu pedido está completo!</div>
            </div>
          </div>
          <div class="columns content-checkout-wrap is-flex-wrap-wrap">
            <div class="column is-12 mb-6 loading" v-if="orders.length === 0">
              Loading ...
            </div>
            <div class="column is-12 mb-6" v-if="orders.length > 0">
              <div class="columns is-variable is-8">
                <!-- LEFT COLUMN -->
                <div class="column is-8 left-column">
                  <h4 class="title is-size-4 txt-c-mobile">¡Enhorabuena, tu pedido está completo!</h4>
                  <p class="txt-c-mobile">
                    <strong>Tu pedidos n° <template v-for="order in orders"><span v-bind:key="order.id">{{order.order_number}} </span></template></strong> se ha procesado correctamente, en breve recibirá un correo electrónico de confirmación a <strong>{{user.email}}</strong> con todos los detalles.
                  </p>
                  <div class="columns is-variable is-3 is-flex-wrap-wrap mt-3 mb-5">
                    <div class="column is-6" v-if="shippingLocation !== null">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de envío</strong></p>
                        <p>
                          <strong>{{shippingLocation.address.name}}</strong><br>
                          {{shippingLocation.address.address1}}<br>
                          {{shippingLocation.address.zip}} {{shippingLocation.address.city}} ({{shippingLocation.address.province}}), {{shippingLocation.address.country}}<br>
                          {{shippingLocation.address.phone}}<br><br>
                          <strong>Envío ordinario</strong>
                        </p>
                      </div>
                    </div>
                    <div class="column is-6" v-if="pickupLocation !== null">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de recogida</strong></p>
                        <p>
                          <strong>{{pickupLocation.address.name}}</strong><br>
                          {{pickupLocation.address.address1}}<br>
                          {{pickupLocation.address.zip}} {{pickupLocation.address.city}} ({{pickupLocation.address.province}}), {{pickupLocation.address.country}}<br>
                          {{pickupLocation.address.phone}}<br><br><br>&nbsp;
                        </p>
                      </div>
                    </div>
                    <div class="column" v-bind:clas="{'is-12': pickupLocation !== null, 'is-6': pickupLocation === null}" v-if="billingLocation !== null">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de facturación</strong></p>
                        <p>
                          <strong>{{billingLocation.address.name}}</strong><br>
                          {{billingLocation.address.address1}}<br>
                          {{billingLocation.address.zip}} {{billingLocation.address.city}} ({{billingLocation.address.province}}), {{billingLocation.address.country}}<br>
                          {{billingLocation.address.phone}}<br><br>&nbsp;
                        </p>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Método de pago</strong></p>
                        <p>
                          <strong>Mastercard que termina con 4899</strong><br>
                          Cad. 9/24<br>
                          John Doe<br>
                        </p>
                      </div>
                    </div>
                    <div class="column is-6 is-offset-6">
                      <button class="button is-primary is-fullwidth is-medium mt-4" @click="goTo('/clinic/orders')">Ver todos mis pedidos</button>
                    </div>
                  </div>
                </div>

                <!-- RIGTH COLUMN -->
                <div class="column is-4 has-background-light recap-sidebar">
                  <p><strong class="txt-yellow is-uppercase">Resumen del pedido</strong></p>
                  <template v-for="order in orders">
                    <div v-bind:key="order.id">
                      <template v-for="item in order.order_items">
                        <div class="recap-service-wrap" v-bind:key="item.id">
                          <div class="recap-service-icon">
                            <span class="icon-tooth"></span>
                          </div>
                          <div class="recap-service-info">
                            <p>
                              <strong>{{item.product_name}}</strong><br>
                              {{item.first_name + ' ' + item.last_name}}
                            </p>
                          </div>
                          <div class="recap-service-price">{{item.total_price / 100}}€</div>
                        </div>
                      </template>

                      <div class="recap-line mt-4">
                        <div class="line-left">
                          <p><strong class="txt-yellow is-uppercase">SUBTOTAL</strong></p>
                        </div>
                        <div class="line-right">
                          <p>{{order.total_price / 100}} €</p>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </template>

                  <div class="recap-line">
                    <div class="line-left">
                      <p><strong class="txt-yellow is-uppercase">Coste de envío estimado</strong></p>
                    </div>
                    <div class="line-right">
                      <p v-if="shipmentCost === 0">GRATIS</p>
                      <p v-if="shipmentCost !== 0">{{shipmentCost}}€</p>
                    </div>
                  </div>

                  <template v-for="lab in recapLabs">
                    <div class="recap-line" v-bind:key="lab.id">
                      <div class="line-left">
                        <p>{{lab.laboratory_name}}</p>
                      </div>
                      <div class="line-right">
                        <p v-if="lab.shipmentCosts === 0">GRATIS</p>
                        <p v-if="lab.shipmentCosts !== 0">{{lab.shipmentCosts}}€</p>
                      </div>
                    </div>
                  </template>

                  <div class="recap-line">
                    <div class="line-left">
                      <p><strong class="txt-yellow is-uppercase">Código promocional</strong></p>
                    </div>
                    <div class="line-right">
                      <p>-</p>
                    </div>
                  </div>

                  <div class="recap-line">
                    <div class="line-left">
                      <p><strong class="txt-yellow is-uppercase">TOTAL</strong></p>
                    </div>
                    <div class="line-right">
                      <p>{{totalPrice / 100}} €</p>
                    </div>
                  </div>

                  <hr>

                  <h4 class="title is-size-4">¿Necesitas ayuda?</h4>
                  <p>
                    Cras mattis consectetur purus sit amet fermentum. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. Curabitur blandit tempus porttitor.
                  </p>
                  <ul class="mt-3 mb-3">
                    <li>
                      <a class="is-underlined" href="#">Contactar con nosotros</a>
                    </li>
                    <li>
                      <a class="is-underlined" href="#">Ver la sección de ayuda</a>
                    </li>
                    <li>
                      <a class="is-underlined" href="#">Política de envíos y devoluciones</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer.vue'
// import { GetRandomInt } from '@/utils/utils.js'

export default {
  name: 'Completed',
  components: {
    Footer
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      clinic: 'user/clinic',
      locations: 'clinic/locations'
    })
  },
  data () {
    return {
      shippingLocation: null,
      billingLocation: null,
      pickupLocation: null,
      orders: [],
      totalPrice: 0,
      recapLabs: [],
      shipmentCost: 0
    }
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    },
    initRecapLabs (order) {
      // const self = this
      for (let i = 0; i < order.order_items.length; i++) {
        const isFound = this.recapLabs.some(element => {
          if (element.id === order.order_items[i].laboratory_id) {
            return true
          }

          return false
        })

        if (!isFound) {
          this.shipmentCost += 0
          this.recapLabs.push({
            id: order.order_items[i].laboratory_id,
            laboratory_name: order.order_items[i].laboratory_name,
            shipmentCosts: 0
          })
        }
      }
    },
    fetchOrder () {
      const self = this
      const orderList = this.$route.params.orderId.split('|')

      for (let i = 0; i < orderList.length; i++) {
        this.$store.dispatch('clinic/getOrder', {
          orderId: orderList[i],
          cb: (data) => {
            if (i === 0) {
              self.fetchLocations()
            }
            self.totalPrice += data.total_price
            self.orders.push(data)
            self.initRecapLabs(data)
          },
          cbError: () => {
            const errorMessage = '¡Error! Can\'t fetch order number ' + self.$route.params.orderId
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: errorMessage
              }
            })
          }
        })
      }
    },
    setLocations () {
      for (let i = 0; i < this.locations.length; i++) {
        if (this.orders[0].shipping_location_id === this.locations[i].id) {
          this.shippingLocation = this.locations[i]
        }
        if (this.orders[0].pickup_location_id === this.locations[i].id) {
          this.pickupLocation = this.locations[i]
        }
        if (this.orders[0].billing_location_id === this.locations[i].id) {
          this.billingLocation = this.locations[i]
        }
      }
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('clinic/getLocations', {
        clinicId: this.clinic.id,
        cb: (data) => {
          self.setLocations()
        },
        cbError: () => {
          const errorMessage = '¡Error! Can\'t get locations'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: errorMessage
            }
          })
        }
      })
    }
  },
  created () {
    this.fetchOrder()
  }
}
</script>

<style scoped lang="scss">
.checkout-order-details{
  width: 100%;
  section{
    width: 100%;
    padding: 60px 0px;
    .checkout-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .tab-wrap{
        width: 100%;
        background-color: #57567C;
        background-color: #F6F4EF;
        border-radius: 4px 4px 0px 0px;
        height: 66px;
        padding: 0px;
        .columns{
          height: 100%;
          .column{
            height: 100%;
            line-height: 45px;
          }
        }
      }
      .content-checkout-wrap{
        padding: 60px;
        .loading{
          height: 400px;
        }
        .left-column{
          .recap-order-box{
            border-radius: 8px;
            padding: 21px 28px;
          }
        }
        .recap-sidebar{
          border-radius: 8px;
          padding: 21px 28px;

          .recap-service-wrap{
            display: flex;
            margin-top: 21px;
            &:last-child{
              margin-bottom: 21px;
            }
            .recap-service-icon{
              position: relative;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
              margin-right: 14px;
              .icon-tooth{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
                color: #57567C;
              }
            }
            .recap-service-info{
              width: calc(100% - 104px);
            }
            .recap-service-price{
              width: 50px;
              text-align: right;
            }
          }
          .recap-line{
            width: 100%;
            display: flex;
            margin-bottom: 14px;
            .line-left{
              width: calc(100% - 50px);
              &.has-input{
                width: calc(100% - 114px);
                margin-right: 14px;
              }
            }
            .line-right{
              text-align: right;
              width: 50px;
              &.has-input{
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .checkout-order-details{
    section{
      .checkout-wrap{
        .content-checkout-wrap{
          .left-column{
            padding: 0px;
            .columns{}
          }
        }
      }
    }
  }
}
</style>
